import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

const dsn = 'https://fe0e5cb029ab49f7bb2b49eb8590b8f7@sentry.io/1784109';

export const client = new Sentry.BrowserClient({ dsn });

if (!window.AppDev) {
    Sentry.init({
        dsn,
        ignoreErrors: [
            'ContaoCookiebar is not defined',
            'Cannot read properties of null (reading \'querySelector\')',
            'undefined is not an object (evaluating \'r["@context"].toLowerCase\')',
        ],
        integrations: [
            new VueIntegration({
                Vue,
                attachProps: true,
            }),
        ],
    });
}

export function sentryWrapper(callback) {
    if (window.AppDev) {
        callback();
    } else {
        Sentry.wrap(callback);
    }
}
