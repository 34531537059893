<template>
    <div :class="['loading', cssClass]">
        <div class="loading__icon" v-if="iconClass">
            <i :class="iconClass"> </i>
        </div>
        <div class="loading__title" v-if="$slots.title">
            <slot name="title"></slot>
        </div>
        <div class="loading__text" v-if="$slots.text">
            <slot name="text"></slot>
        </div>
        <div class="loading__spinner">
            <div class="three-dot">
                <div class="dot-carousel"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loading',
        props: {
            cssClass: {type: String, default: ''},
            iconClass: {type: String, default: ''},
        },
    }
</script>
