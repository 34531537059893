var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["loading", _vm.cssClass] }, [
    _vm.iconClass
      ? _c("div", { staticClass: "loading__icon" }, [
          _c("i", { class: _vm.iconClass })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$slots.title
      ? _c("div", { staticClass: "loading__title" }, [_vm._t("title")], 2)
      : _vm._e(),
    _vm._v(" "),
    _vm.$slots.text
      ? _c("div", { staticClass: "loading__text" }, [_vm._t("text")], 2)
      : _vm._e(),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading__spinner" }, [
      _c("div", { staticClass: "three-dot" }, [
        _c("div", { staticClass: "dot-carousel" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }