var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "newsletter newsletter--" + this.$config.mode }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "formbody" }, [
          _c(
            "div",
            { staticClass: "newsletter-subscribe" },
            [
              _vm.message
                ? _c("div", {
                    class:
                      "newsletter-subscribe__message newsletter-subscribe__message--" +
                      _vm.messageType,
                    domProps: { innerHTML: _vm._s(_vm.message) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loading
                ? _c("Loading")
                : _vm.showForm
                ? _c("div", { staticClass: "input-group" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-group__widget widget-text mandatory"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email"
                            }
                          ],
                          staticClass: "text mandatory",
                          attrs: {
                            type: "email",
                            name: "email",
                            placeholder: "Enter your e-mail address …",
                            required: ""
                          },
                          domProps: { value: _vm.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c("div", { staticClass: "widget-submit" }, [
                        _c(
                          "button",
                          { staticClass: "submit", attrs: { type: "submit" } },
                          [
                            _vm._v(
                              _vm._s(
                                this.$config.mode === "subscribe"
                                  ? "Subscribe"
                                  : "Unsubscribe"
                              )
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              this.$config.mode === "subscribe"
                ? _c("div", { staticClass: "widget widget-explanation" }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("span", [
                        _vm._v("The controller of your personal data is: "),
                        _c("strong", [
                          _vm._v(_vm._s(this.$config.shortAddress))
                        ]),
                        _vm._v(".")
                      ]),
                      _vm._v(" "),
                      this.$config.unsubscribePage
                        ? _c(
                            "a",
                            { attrs: { href: this.$config.unsubscribePage } },
                            [_vm._v("Click here to unsubscribe")]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("details", [
                      _c("summary", [
                        _vm._v(
                          "Please read the full disclaimer on data processing, available here"
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "We process the following categories of your personal data: e-mail address. The controller of personal data is " +
                            _vm._s(this.$config.billingAddress) +
                            ", e-mail: "
                        ),
                        _c("a", { attrs: { href: "mailto:info@veello.com" } }, [
                          _vm._v("info@veello.com")
                        ]),
                        _vm._v(
                          ". Your personal data will be processed for the purpose of direct marketing (Article 6(1)(f) of the GDPR) by the Controller. The personal data will be kept until such time as you unsubscribe from the newsletter or file an objection to its processing for this purpose. Providing your personal data is voluntary, but necessary for us to send you messages. You have the right of access to, rectification, and erasure of your personal data, and of restriction of the processing thereof. You also have the right to data portability and the right to object to the processing of your personal data. You have the right to lodge a complaint with the President of the Polish Personal Data Protection Office. Personal data will be provided only to our trusted subcontractors, i.e. IT service providers."
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }