import axios from 'axios';
import * as Sentry from '@sentry/browser';

const transactions = new Map();

// Creat the ongoing requests pool
window.appOngoingRequests = window.appOngoingRequests || new Map();

axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';

// Add a request interceptor for Sentry
axios.interceptors.request.use((config) => {
    transactions.set(config, Sentry.startTransaction({ name: config.url }));

    return config;
});

// Add a response interceptor for Sentry
axios.interceptors.response.use((response) => {
    const transaction = transactions.get(response.config);

    if (transaction !== undefined) {
        transaction.finish();
        transactions.delete(response.config);
    }

    return response;
});

// Override the default GET method to avoid duplicate requests
axios.__get = axios.get;
axios.get = function (uri, config = {}) {
    const key = `${uri}${JSON.stringify(config)}`;

    // Return the request from ongoing, if it exists
    if (window.appOngoingRequests.has(key)) {
        return window.appOngoingRequests.get(key);
    }

    const request = axios.__get.call(this, ...arguments);

    // Remove the requests from ongoing after it finishes
    request.finally(() => window.appOngoingRequests.delete(key));

    // Store the request in ongoing
    window.appOngoingRequests.set(key, request);

    return request;
}

export default axios;
