import { client } from './sentry';
import GLightbox from 'glightbox/src/js/glightbox';

export function displayError(error = null, tryAgainCallback = null) {
    if (tryAgainCallback === null) {
        tryAgainCallback = () => window.location.reload();
    }

    const lightbox = GLightbox({
        elements: [{
            content: `<div class="modal">
    <div class="modal__body">
        <div class="message-error">
            <h4 class="message-error__title">Something went wrong</h4>
            <div class="message-error__text">${error || 'An error occurred! Please try again or contact us.'}</div>
            <div class="message-error__btn buttons-group buttons-group--no-wrap">
                <a href="#" class="btn btn-primary btn-icon btn-icon--hide-icon-xs" data-lightbox-try-again><span>Try again</span><i class="icon-repeat"></i></a>
                <a href="/contact" class="btn btn-secondary btn-icon btn-icon--hide-icon-xs"><span>Contact us</span><i class="icon-message-square"></i></a>
            </div>
        </div>
    </div>
</div>`,
            type: 'inline',
            width: 'auto',
            height: 'auto',
        }],
        onOpen: () => {
            const closeBtn = document.querySelector('.gclose');
            const modalContainer = document.querySelector('.gslider');

            if (closeBtn && modalContainer) {
                modalContainer.appendChild(closeBtn);
            }

            document.querySelector('a[data-lightbox-try-again]').addEventListener('click', e => {
                e.preventDefault();
                tryAgainCallback(lightbox);
            });
        },
    });

    lightbox.open();

    reportError(error);
}

export function handleAjaxError(error, tryAgainCallback = null) {
    displayError(null, tryAgainCallback);
    reportAjaxError(error);
}

export function reportError(error) {
    if (window.AppDev) {
        console.error(error);
        return;
    }

    if (typeof error === 'string') {
        client.captureMessage(error);
    } else {
        client.captureException(error);
    }
}

export function reportAjaxError(error) {
    if (window.AppDev) {
        console.error(error);
        return;
    }

    client.captureMessage(`Ajax request to ${error.response.config.url} failed: ${error.response.statusText} (${error.response.status})`, {
        extra: error.response,
    });
}
